
<template>
  <div class="product-layout">
    <van-nav-bar left-arrow :safe-area-inset-top="true" fixed :border="false" @click-left="goBack"
                 class="nav" @click-right="go">
      <template #right>
        <van-image :src="require('../../assets/share.png')" width="25px" height="25px"></van-image>
      </template>
    </van-nav-bar>

    <div class="mod_container">
      <div id="mod" class="mod" @click="playMusic">
        <van-loading class="mod-load" type="spinner" size="30px" v-if="showLoadIcon"></van-loading>
      </div>
      <van-cell class="fullscreen_btn" @click="showPopup" v-if="isShow">
        <van-image class="fullscreen_btn_img" :src="require('../../assets/icons/fullscreen_btn.png')"></van-image>
      </van-cell>
    </div>
    <!--    <van-popup closeable position="top" v-model:show="show" :overlay="true">sdafff内容dsafsdafsfsdf</van-popup>-->
    <van-popup v-model:show="show" @opened="initFS3D" @closed="closeFS3D" overlay-class="mask_style" closeable
               :close-on-click-overlay="true"  lazy-render close-on-popstate safe-area-inset-bottom
               get-container=".product-layout" :style="{width: '100%',height: '100%'}">
      <div id="fullScreenContainer" class="fullScreenContainer"></div>
    </van-popup>
    <div class="info">
      <div class="product_name">
        <div class="red_bar"></div>
        <div class='info-text' v-show="!isShow">{{ product.name }}</div>
        <div class='info-text' v-show="isShow">{{ product.productName }}</div>
        <div class="red_bar"></div>
      </div>
      <div class='info-innerBox' v-if="!isShow">
        <div class="goods-left-item-limit">
          限量
        </div>
        <div class="goods-left-item-count">
          {{ product.total }}份
        </div>
        <div class="goods-left-item-tag" v-if="product.lables">
          {{ product.lables }}
        </div>
      </div>
      <div class='serial-number' v-if="isShow">
        <van-image class="serial-number-img" :src="require('../../assets/collection_detail/chain_logo.png')" fit="fill"></van-image>
        <div class="serial-number-text">{{product.number}}</div>
      </div>
    </div>
    <div class="collectorTag" v-if="isShow">
      <div class="tagRow">
        <div class="collectorTitle">收藏者</div>
        <div class="msg-size">{{ myProductDetail.userName }}</div>
      </div>
      <div class="tagRow">
        <div class="collectorTitle">生成时间</div>
        <div class="msg-size">{{ myProductDetail.generatorTime }}</div>
      </div>
      <div class="tagRow">
        <div class="collectorTitle">藏品哈希值</div>
        <div class="block-chain">

          <div class="block-chain-code">{{ myProductDetail.hashCode }}</div>
          <van-image @click="copyAddress(myProductDetail.hashCode)" class="block-img"
                     :src="require('../../assets/icon-copy.png')" width="30px" height="23px" fit="fill"></van-image>
          <van-image class="seal" :src="require('../../assets/watermark.png')" fit="fill"></van-image>
        </div>
      </div>
    </div>
    <!--      <div class="collectorTag-msg">
            <van-image class="seal" :src="require('../../assets/文悟链-图标-水印章.png')" fit="fill"></van-image>
            <div class="msg-size">{{ myProductDetail.userName }}</div>
            <div class="msg-size">{{ myProductDetail.generatorTime }}</div>
            <div class="block-chain">
              <div>{{ myProductDetail.hashCode }}</div>
              <van-image @click="copyAddress(myProductDetail.hashCode)" class="block-img"
                :src="require('../../assets/icon-copy.png')" width="30px" height="23px" fit="fill"></van-image>
            </div>
          </div>-->
    <div class="product_detail">
      <div class="detail-info">藏品故事</div>
      <div class="detail-img">
        <van-image :src="product.storyImages" radius="20px 20px 20px 20px " class="lazy_img"
                   style="margin-top:15px;margin-bottom: 15px;"></van-image>
      </div>
      <div class="detail-info-bottom">藏品信息</div>
      <div class='details'>
        <div class="details-detail">
          <div class="details_title">链上哈希</div>
          <div class="details_value" v-if="isShow">{{ product.releaseHashCode }}</div>
          <div class="details_value" v-if="!isShow">{{ product.hashCode }}</div>
        </div>
        <div class="details-detail">
          <span>创作方</span>
          <span>{{ product.creator }}</span>
        </div>
        <div class="details-detail">
          <span>发行方</span>
          <span>{{ product.issuer }}</span>
        </div>
        <div class="details-detail">
          <span>发行日期</span>
          <span>{{ product.releaseTime }}</span>
        </div>
        <div class="details-detail">
          <span>发行数量</span>
          <span>{{ product.total }}份</span>
        </div>
        <div class="details-detail">
          <span>发行方式</span>
          <span>免费</span>
        </div>
      </div>
      <div class="botton-introduce">
        <div>
          <img class="chain-icon" src="../../assets/icons/chain_logo_black.png">
        </div>
        <div class="chain-botton">提供区块链技术服务支持</div>
      </div>
    </div>
    <div class="drawNFT" v-show="!isShow">
      <span class="leftLable" >免费领取</span>

      <div class="getForFreeBtn" v-show="product.seckillStock > 0 && product.dateDiff < 0">
       <span class="getStockTxt">剩余{{ product.seckillStock }}份
        <span class="getForFreeTxt" @click="drawNFT">免费领取</span>
      </span>
      </div>
      <!--      <span v-show="parseInt(product.seckillStock) > 0" class="leftLable" @click="drawNFT">免费领取</span>

            <div class="getForFreeBtn"  v-show="parseInt(product.seckillStock) > 0" >
             <span class="drawNFT-span2">剩余{{ product.seckillStock }}份
              <span class="getForFreeTxt" @click="drawNFT">免费领取</span>
            </span>
            </div>-->

      <div class="waitForFreeBtn" v-show="product.seckillStock > 0 && product.dateDiff >0">
        <van-count-down v-if="product.isReady" :time="product.dateDiff"
                        format="HH时 mm分 ss秒"
                        @finish="startSelling"
                        class="coutDown" />
        <span class="waitForFreeTxt">即将开始</span>
      </div>

      <div v-show="(product.seckillStock === 0)" class="outForFreeBtn">
        <span class="outForFreeTxt">已领完</span>
      </div>
    </div>

    <div class="myNFT-action" v-if="isShow">
      <div @click="toMyshare">分享</div>
      <div @click="toBlockchainCertificate">查看证书</div>
      <div @click="showTranform">转赠</div>
    </div>

    <!--           -------------转赠弹出开始----------------                  -->
    <van-popup v-if="showTransfer"  v-model:show="showTransfer" closeable position="bottom" :close-on-click-overlay="false">
      <TranferDialog :tips="product.tips" @submit="transfer"></TranferDialog>
    </van-popup>

    <van-popup v-if="showConfirm" v-model:show="showConfirm" :close-on-click-overlay="false">
      <ConfirmTranfer :is-result="tranformData.isConfirmResult" @submitTranfer="submitTranferInfo" @cancel="clickCancelConfirmAction" :address="tranformData.transformAddress" @backMine="backToMine"></ConfirmTranfer>
    </van-popup>

    <!--           -------------转赠弹出结束----------------                  -->





  </div>
</template>

<script>
import {ref, reactive, onMounted, toRefs, getCurrentInstance, onUnmounted} from "vue";
import { RouterView, useRouter,onBeforeRouteLeave } from "vue-router";
import Certification from "@/components/certification";
import path from "path";
import * as THREE from 'three';
import {
  Scene,
  PerspectiveCamera,
  Color,
  WebGLRenderer,
  LinearToneMapping,
  AmbientLight,
  Vector3,
  Group,
  PMREMGenerator,
  UnsignedByteType,
  HemisphereLight,
} from 'three';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
import { setTimeout } from "timers";
import ResourceTracker, {clearToast, showFailToast,getInervalHour, showLoading} from "../../utils/util";

export default {
  name: "product",
  setup() {
    let resMgr = new ResourceTracker();
    const track = resMgr.track.bind(resMgr);
    const { proxy } = getCurrentInstance();
    console.log("proxy", proxy)
    const $router = useRouter();
    const zoomFactor =ref(17)
    const state = reactive({
      product: {
        name: '',
        hash_code: "",
        releaseHashCode:"",
        images: "",
        issuer: "",
        productName: "",
        releaseTime: "",
        storyImages: "",
        total: "",
        lables: "",
        startTime: "",
        dateDiff: 0,
        hourDiff: 0,
        id: "",
        number:"",
        hashCode:"",
        creator:"",
        shareImage:"",
        saleTime:"",
        threedSize:20,
        seckillStock:0,
        isReady:false
      },
      productInfo: {
        number:"",
      },
      myProductDetail: {
        userName:"",
        generatorTime:"",
        hashCode:"",
      },
      transferForm:{},
      show:false
    });
    let threeD = {
      group: null,
      camera: null,
      scene: null,
      renderer: null,
      mesh: null,
      degree: 0,
      width: document.documentElement.clientWidth,
      height: 520,
      clock: null,
      axisHelper: null,
      mainControls:null,
      controls: null,
      gltfLoader: null,
      dracoLoader: null,
      decoderPath: '/model/draco/',
      decoderConfigType: 'js',
      gltfPath: '',
      envPath: '/model/env/hdr-qfmy-js.hdr',
      spotLight: null,
      hemiLight: null,
      glb: null,
      pos: null
    }
    const isShow = $router.currentRoute.value.query.isShow ? $router.currentRoute.value.query.isShow : false;
    const showLoadIcon=ref(true)
    let productId = '';
    if (isShow) {
      state.myProductDetail = JSON.parse($router.currentRoute.value.query.productDetail.toString())
      productId = state.myProductDetail.productId
      let myProductId = state.myProductDetail.id
      console.log('productId1:'+productId)
      proxy.$http.get("/wenwu-order/order/collection/detail?id=" + myProductId).then(res => {
        if(res.data.code===200){
          state.product = res.data.data;
          proxy.$http.get("/wenwu-order/order/collection/threed?id=" + state.myProductDetail.id).then(res => {
            threeD.gltfPath = res.data.data.threeD
            threeD.envPath = res.data.data.threedLight
            init()
            animate()
          })
        }else if(res.data.code === 3018) {
          const toast = proxy.$toast.loading({
            message: res.data.message,	//加载的时候显示的文字
            duration: 2000,		//展现时长(ms)，值为 0 时，toast 不会消失
            overlay: true, 	//是否显示遮罩层
            forbidClick: true	//是否禁止背景点击
          });
          setTimeout(() => {
            $router.push({path: "approve"})
          }, 2000);
        }
      })
    } else {
      productId = $router.currentRoute.value.query.productId
      console.log('productId2:'+productId)
      proxy.$http.get("/wenwu-commodity/product/info?product_id=" + productId).then(res => {
        state.product = res.data.data;
        productId = state.product.threeD
        console.log("state.product.lables:"+state.product.lables)
        threeD.gltfPath = res.data.data.threeD
        threeD.envPath = res.data.data.threedLight
        init()
        animate()
        console.log('threeD:'+productId)
        let endDate = new Date();//获取当前时间
        console.log("当前时间：", endDate.getTime())
        console.log("购买开始时间：", new Date(state.product.saleTime).getTime())
        let saleTime = Date.parse(state.product.saleTime.replace(/-/g, "/"));
        state.product.dateDiff = new Date(saleTime).getTime() - endDate.getTime();//时间差的毫秒数
        console.log("时间差的毫秒数", state.product.dateDiff)
        state.product.hourDiff = getInervalHour(new Date(saleTime),endDate)
        if(state.product.hourDiff>=0 && state.product.hourDiff<24){
          state.product.isReady = true
        }
      })
    }
    onMounted(() => {

    })
    onUnmounted(()=>{
        cancelPlay();
    })
    onBeforeRouteLeave((to, from, next) => {
      cancelPlay()
      clearScene()
      next()
    })
    const handleStoreName = () => {
      $router.push("/storeDetail");
    };
    const go = (() => {
      $router.push({
        path: "/nftDetail/myShare",
        query: { productDetail: JSON.stringify(state.product) }
      })
    })
    const toBlockchainCertificate = (() => {
      $router.push({
        path: "/blockchainCertificate",
        query: { productDetail: JSON.stringify(state.myProductDetail) }
      })
    })
    const toMyshare = (() => {
      $router.push({
        path: "/nftDetail/myShare",
        query: { productDetail: JSON.stringify(state.product) }
      })
    })
    const goBack = (() => {
      $router.back()
    })
    const showPopup = (()=>{
      state.show = true
    })
    /*const getInervalHour = (startDate, endDate)=>{
      let ms = endDate.getTime() - startDate.getTime();
      if (ms < 0) return 0;
      return Math.floor(ms/1000/60/60);
    }*/
    const drawNFT = (() => {
      const params = {
        productId: $router.currentRoute.value.query.productId,
        activityType: 1
      }
      console.log(params)
      //秒杀
      proxy.$http.post("/wenwu-order/order/seckill", params).then(res => {
        const toast = proxy.$toast.loading({
          message: '请稍后...',	//加载的时候显示的文字
          duration:0,		//展现时长(ms)，值为 0 时，toast 不会消失
          overlay:true, 	//是否显示遮罩层
          forbidClick: true	//是否禁止背景点击
        });
        if (res.data.code === 200) {
          //查询秒杀结果返回订单id
          setTimeout(() => {
            proxy.$http.get("/wenwu-order/order/result?product_id=" + params.productId).then(res2 => {
              //调用支付接口，免费商品不会支付，只执行相关业务逻辑
              proxy.$http.post("/wenwu-order/pay/order", { orderId: res2.data.data, payType: 0 }).then((res3) => {
                toast.clear();
                console.log('state.product.shareImage:'+state.product.shareImage)
                $router.push({
                  path: "/drawProduct",
                  query: {shareImage: state.product.shareImage}
                })
              })
            })
          }, 3000);
        }else{
          toast.clear();
          proxy.$toast.fail(res.data.message)
        }
      })
    })
    const startSelling = (()=> {
      state.product.dateDiff = -1
    })
    const copyAddress = ((str) => {
      //新建一个文本框
      let oInput = document.createElement('input');
      //赋值给文本框
      oInput.value = str;
      document.body.appendChild(oInput);
      // 选择对象;
      oInput.select();
      // 执行浏览器复制命令
      document.execCommand("Copy");
      //复制完成删除掉输入框
      oInput.remove()
      proxy.$toast.success('复制成功');
    })
    const initFS3D = (() => {
      let container= document.querySelector('#fullScreenContainer')
      container.appendChild(threeD.renderer.domElement)
      //threeD.controls.enabled = !threeD.controls.enabled
      initControls(true)
    })
    const closeFS3D= (() => {
      let container= document.querySelector('#mod')
      container.appendChild(threeD.renderer.domElement)
      initControls(false)
      //threeD.controls.enabled = !threeD.controls.enabled
    })
    const init = (() => {
      threeD.scene = new THREE.Scene()
      threeD.group = new THREE.Group()
      //threeD.clock = new THREE.Clock()//默认1s60帧 这样就可以进行循环动画
      threeD.pos = new Vector3(0, 0, 0)
      initRenderer(state.show)
      initCamera()
      initControls(false)
      initLight()
      initHDRMapping()
      initGltfLoader()
      //initSpotLight()
    })
    const animate = (() => {
      threeD.renderer.setAnimationLoop(render.bind())
      //requestAnimationFrame(threeD.animate)//渲染循环，以每秒60次的频率来绘制场景
      // threeD.cube.rotation.y += 0.005
      //threeD.scene.dispose()
      //render()
    })
    const render = (() => {
      //const d = (++degree * Math.PI) / 180;
      threeD.controls.update()//触发事件
      //const spt = threeD.clock.getDelta()
      //console.log('渲染间隔时间',spt);
      //threeD.spotLight.position.set(threeD.camera.position.x,threeD.camera.position.y,threeD.camera.position.z);
      threeD.renderer.render(threeD.scene, threeD.camera) //渲染，显示摄像头在屏幕上看到的内容
      //threeD.group.rotateY(-0.01)//使模型围绕y轴旋转
      //threeD.group.rotation.y -=0.006//使模型围绕y轴旋转
      //group.rotation.set(0, d, 0);
    })
    const initRenderer = ((isFullScreen) => {
      threeD.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true })//创建渲染器，将相机看到的内容绘制到屏幕上
      //threeD.renderer.setSize(container.clientWidth, container.clientHeight)
      let container= document.querySelector('#mod')
      container.appendChild(threeD.renderer.domElement)
      threeD.renderer.setSize(threeD.width, threeD.height);//渲染器canvas宽高设为与窗口一致
      threeD.renderer.setPixelRatio(devicePixelRatio);
      threeD.renderer.toneMapping = THREE.LinearToneMapping;
      threeD.renderer.toneMapping = THREE.ACESFilmicToneMapping;//色调映射-电影级别
      threeD.renderer.toneMappingExposure = 1.6; // 曝光系数
    })

    const initCamera = (() => {
      threeD.camera = new THREE.PerspectiveCamera(18, threeD.width / threeD.height, 1, 10000); //摄像机视角15度，画幅比例等
      threeD.camera.position.set(2600, 60, 2800) //摄像机位置
      threeD.camera.lookAt(threeD.pos.x, threeD.pos.y, threeD.pos.z)//相机看向那个点
      //threeD.camera.updateProjectionMatrix()
      threeD.scene.add(track(threeD.camera))
      // threeD.axisHelper = new THREE.AxesHelper(10);//添加辅助坐标系坐标轴 红x 绿y 蓝z 颜色可以后期自己设置 参数是坐标轴长短 太小就会在物体里面
    })
    const initControls = ((isFullScreen) => {
      if (isFullScreen) {
        threeD.controls = new OrbitControls(threeD.camera, threeD.renderer.domElement)//相机控件
        threeD.controls.minDistance = 10
        threeD.controls.maxDistance = 1500
        threeD.controls.minPolarAngle = 0
        threeD.controls.maxPolarAngle = 2.5//Math.PI / 2
        threeD.controls.enableDamping = true //设置阻尼也就是惯性 这个设置完必须在渲染的每帧里面执行updata方法(在动画循环里调用.update())
        threeD.controls.target.set(threeD.pos.x, threeD.pos.y, threeD.pos.z)//跟相机的lookAt坐标一致
        threeD.controls.autoRotate = false
        threeD.controls.enablePan = true // 是否开启右键拖拽
        threeD.controls.dampingFactor = 0.1 // 动态阻尼系数 就是鼠标拖拽旋转灵敏度，阻尼越小越灵敏
        threeD.controls.rotateSpeed = 2
      } else {
        initCamera()
        threeD.controls = new OrbitControls(threeD.camera, threeD.renderer.domElement)//相机控件
        threeD.controls.minDistance = 20
        threeD.controls.maxDistance = 1500
        threeD.controls.minPolarAngle = 0
        threeD.controls.maxPolarAngle = 2.5//Math.PI / 2
        threeD.controls.autoRotate = true
        threeD.controls.autoRotateSpeed = 4.0
        threeD.controls.enableZoom = false
        threeD.controls.enableRotate = false
        threeD.controls.enablePan = false
        threeD.controls.target.set(threeD.pos.x, threeD.pos.y, threeD.pos.z)//跟相机的lookAt坐标一致
      }
    })
    const initSpotLight = (() => {
      threeD.spotLight = new THREE.SpotLight(0xFFFFFF)
      threeD.spotLight.position.set(500, 40, 500)
      threeD.spotLight.castShadow = true
      threeD.spotLight.shadow.mapSize = new THREE.Vector2(1024, 1024)
      threeD.spotLight.shadow.camera.far = 130
      threeD.spotLight.shadow.camera.near = 40
      threeD.spotLight.distance = 800
      threeD.spotLight.angle = 0.8
      threeD.spotLight.shadow.camera.fov = 120
      threeD.spotLight.decay = 2
      threeD.spotLight.intensity = 0.5
      threeD.spotLight.power = 10
      threeD.scene.add(track(threeD.spotLight))
    })
    const initLight = (() => {
      const ambient = new THREE.AmbientLight(0xffffff, 1)//添加环境光
      threeD.scene.add(track(ambient))
      const hemiLight = new HemisphereLight(0xffffff, 0x000000, 1);
      hemiLight.position.set(0, 100, 0);
      threeD.scene.add(track(hemiLight));
      const pointLight = new THREE.PointLight(0xffffff, 1.4)//添加点光源
      pointLight.position.set(200, 100, 200)//设置点光位置
      //threeD.scene.add(pointLight)
      //threeD.camera.add(pointLight)
      const pointLightHelper = new THREE.PointLightHelper(pointLight, 50);// 可视化点光源
      //threeD.scene.add(pointLightHelper);
    })
    const initHDRMapping = (() => {
      const pmremGenerator = new PMREMGenerator(threeD.renderer); // 使用hdr作为背景色
      pmremGenerator.compileEquirectangularShader();
      const rgbLoader = new RGBELoader();
      rgbLoader.loadAsync(threeD.envPath).then((texture) => {
        texture.mapping = THREE.EquirectangularReflectionMapping;
        //threeD.scene.background = texture;//将加载的材质texture设置给背景和环境
        threeD.scene.environment = texture;
      })
    })
    const initGltfLoader = (() => {
      threeD.gltfLoader = track(new GLTFLoader())
      threeD.dracoLoader = track(new DRACOLoader())
      threeD.dracoLoader.setDecoderPath(threeD.decoderPath)
      threeD.dracoLoader.preload()
      threeD.dracoLoader.setDecoderConfig({ type: threeD.decoderConfigType })
      threeD.gltfLoader.setDRACOLoader(threeD.dracoLoader)
      threeD.gltfLoader.load(threeD.gltfPath, (glb) => {
        //threeD.scene.add(track(glb.scene));
        //glb.scene.position.set(0,0,0)
        glb.scene.scale.set(parseInt(state.product.threedSize)*zoomFactor.value,
          parseInt(state.product.threedSize)*zoomFactor.value,
          parseInt(state.product.threedSize)*zoomFactor.value);//网格模型缩放

        threeD.v1T1 = new THREE.Vector3(glb.scene.x, glb.scene.y, glb.scene.z)
        //threeD.spotLight.target = glb.scene
        threeD.glb = track(glb.scene)
        threeD.group.add(threeD.glb)
        threeD.scene.add(track(threeD.group))
        threeD.group.position.set(threeD.pos.x, threeD.pos.y, threeD.pos.z)
        showLoadIcon.value=false
      }, undefined, function (error) {
        // showLoadIcon.value=false 未加载出来也移除加载中状态
        console.error(error);
      });
    })
    const clearScene = (() => {
      if(threeD.scene){
        cancelAnimationFrame(animate);
        /*threeD.scene.traverse((child) => {
          if (child.material) {
            child.material.dispose();
          }
          if (child.geometry) {
            child.geometry.dispose();
          }
          child = null;
        });*/
        //threeD.sceneDomElement.innerHTML = '';
        threeD.renderer.forceContextLoss();
        threeD.renderer.dispose();
        threeD.scene.clear();
        threeD.scene = null;
        threeD.camera = null;
        threeD.controls = null;
        threeD.renderer.domElement = null;
        threeD.renderer = null;
        threeD.sceneDomElement = null;
      }
      console.log('clearScene');
    })


    let player;
    let videoIsPlay = false;
    //-----------音乐播放-----------------------
    const playMusic = () => {


      if(showLoadIcon.value){
        return;
      }


      if(isShow  && state.product.threedMusicUrl.length > 0){
        if(player != null){
          player.pause();
          player = null;
          videoIsPlay =  false;
        }
        player = new Audio(state.product.threedMusicUrl);
        player.play();
        videoIsPlay =  true;
        player.addEventListener('ended', function(){
          videoIsPlay =  false;
        },false);
      }


    }

    const cancelPlay = () => {
      if(player != null){
        player.pause();
        player = null;
        videoIsPlay =  false;
      }
    }

    //-------------------转赠功能 --------------------------------------

    const tranformData = reactive({
      transformAddress:'',
      isConfirmResult:false,

    })
    const showTranform = () => {
      console.log(state.product)

      //判断是否可以转赠
      showLoading('')
      proxy.$http.get('/wenwu-order/order/collection/cantransfer?id='+state.myProductDetail.id).then(res => {
        clearToast()
        if(res.data.code === 200){
          if(res.data.data){
            tranformData.isConfirmResult = false;
            showTransfer.value = true;
          }else{
            showFailToast(res.data.message,1000)
          }

        }else{
          showFailToast(res.data.message,1000)
        }

      })
    }
    const showTransfer = ref(false);
    const transfer = ((text) => {
      tranformData.transformAddress = text
      showConfirm.value = true;
    })


    const clickCancelConfirmAction = () => {
      showConfirm.value = false
    }
    const showConfirm = ref(false);
    const submitTranferInfo = (() => {


      showLoading('转赠中')
      proxy.$http.post("/wenwu-order/order/collection/transfer",{'id':state.product.id,'hashCode':tranformData.transformAddress}).then(res => {
        clearToast()
        if(res.data.code === 200){
          showConfirm.value = false;
          setTimeout(()=>{
            tranformData.isConfirmResult = true;
            showConfirm.value = true;
          },10)

        }else{
          showFailToast(res.data.message,2000)
        }

      })
    })




    const backToMine = () => {
      $router.replace('/mine')
    }


    //-------------------转赠功能结束  --------------------------------------


    return {
      playMusic,
      clickCancelConfirmAction,
      submitTranferInfo,
      tranformData,
      showTranform,
      transfer,
      showTransfer,
      showConfirm,
      backToMine,
      go,
      goBack,
      ...toRefs(state),
      handleStoreName,
      showPopup,
      drawNFT,
      startSelling,
      copyAddress,
      isShow,
      toBlockchainCertificate,
      showLoadIcon,
      toMyshare,
      init,
      animate,
      render,
      initRenderer,
      initCamera,
      initControls,
      initSpotLight,
      initLight,
      initHDRMapping,
      initGltfLoader,
      initFS3D,
      closeFS3D,
      name,
    };
  }
};
</script>

<style scoped lang="scss" src="./index.scss">

</style>










